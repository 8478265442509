@import '../../styles/colors.scss';

.wrapper {
  border: 1px solid #FFF;
  background-color: #6f6f6f;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: default;

  &[data-type] {
    border: 5px solid rgb(250, 222, 10);
    background-color: rgba(94, 94, 94, 0.3);
  }

  &[data-type='gym'] {
    border: 5px solid rgb(250, 222, 10);
  }

  &[data-type='pokestop'] {
    border: 5px solid #0061AE;
  }

  &[data-type='direct'] {
    border: 2px solid rgb(255, 3, 3);
  }

  &:hover {
    width: 32px;
    height: 32px;
  }

  &.highlight {
    background-color: #F00;
  }
}

.text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
