@import '../../styles/colors.scss';

.wrapper {
  color: $color-white;
}

.header {
  border-bottom: 1px solid $color-variant;
  background-color: $color-primary;
  display: flex;
  padding: 10px;
}

.subheader {
  background-color: $color-secondary;
  padding: 10px;
  display: flex;
}

.subleft {
  flex-grow: 1;
  display: flex;
}

.speed {
  height: 100%;
  display: inline-flex;
  align-items: center;
  
  input {
    height: 100%;
    margin-right: 8px;
  }
}

.subright {
  display: flex;
}

.separator {
  width: 1px;
  background-color: darken($color-secondary, 10);
  border-right: 1px solid lighten($color-secondary, 10);
  margin-left: 20px;
  margin-right: 10px;
}

.logo {
  flex: 1;
  display: flex;  
}

.navbar {
  display: flex;  
}

.controlbar {
  display: flex;  
}

.link {
  text-decoration: none;
  margin: 0 10px;
  padding: 10px;
  color: #333;
  background-color: #DDD;
  border: 1px solid #666;
  cursor: pointer;
  
  &:hover {
    background-color: #EEE;
  }

  &.active {
    background-color: #AAA;
  }
}

