.wrapper {
  display: inline-flex;
  align-items: center;
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 10px;

  input {
    margin-left: 10px;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
