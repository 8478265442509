.wrapper {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.modal {
  position: absolute;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  &_inner {
    background-color: #FFF;
    height: 50%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    textarea {
      resize: none;
      height: 90%;
      width: 90%;
    }
  }
}
