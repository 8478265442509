@import '../../styles/colors.scss';

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  transition: 500ms border-color;

  &[data-active="true"] {
    background-color: $color-info;
  }

  &:not(:last-child):not(.outline) {
    border-bottom: 1px solid $color-line;
  }

  &.outline {
    border-color: $color-variant;
  }
}

.index {
  margin-right: 8px;
}

.position {
  font-size: 10px;
  overflow: hidden;
  flex-grow: 1;
}

.actions {
  display: flex;
  align-items: center;
  
  .action {
    width: 40px;
  }
}
