$color-white: #FFFFFF;
$color-red: #DA2A1C;

$color-primary: #08224E;
$color-secondary: #3DAAE7;
$color-variant: #0061AE;
$color-primary-dark: #18427F;

$color-success: #D9FCD9;
$color-info: #E2F6F7;
$color-info2: #94BDC6;

$color-line: #E2F6F7;
