@import '../../styles/colors.scss';

.wrapper {
  border: 1px solid $color-secondary;
  display: inline-flex;
  height: 40px;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  user-select: none;
  background-color: $color-variant;
  cursor: pointer;

  &:hover {
    background-color: lighten($color-variant, 5);
  }

  &[data-red="true"] {
    background-color: $color-red;
  }

  &[data-active="true"] {
    background-color: $color-secondary;
    border: 1px solid $color-variant;
  }
}

.size {
  &-small {
    height: 30px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
