@import '../../styles/colors.scss';

.wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: lighten($color-info, 3);
  }

  &[data-active="true"] {
    background-color: $color-info;
  }
}

.nameinput {
  padding: 5px;
}

.name {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px;
}

.actions {
  display: flex;
  padding: 10px;
}